import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CourseCard from "./CourseCards";
import Particle from "../Particle";
import spoken from "./SE.png";
import ps from "./PS.png";
import is from "./IS.png";
import cc from "./CC.png";
import phonics from "./Phonics.png";
import abacus from "./AC.png";

const Courses = () => {
  return (
    <Container fluid className="course-section">
      <Particle />
      <Container>
        <h1 className="course-heading">
          <strong className="brown">Our Courses </strong>
        </h1>


        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="course-card">
            <CourseCard
              imgPath={spoken}
              isBlog={false}
              title="Spoken English"
              description="Our Spoken English course is designed to help you achieve a natural speaking style, as well as gain confidence in speaking English. With our Spoken English modules, you will talk, practice and improve your pronunciation and intonation."
              ghLink="/enquiry"
            />
          </Col>

          {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}> */}
          <Col md={4} className="course-card">
            <CourseCard
              imgPath={ps}
              isBlog={false}
              title="Public Speaking And Personality Development"
              description="Our Public Speaking training course is designed to enhance essential skills such as effective communication, confidence in presenting, and the ability to engage and persuade an audience. These non-technical competencies are invaluable for anyone looking to excel in professional presentations, meetings, or public forums. By refining these abilities, individuals can boost their career prospects, establish stronger connections with listeners, and significantly elevate their overall performance and self-assurance."
              ghLink="/enquiry"
            />
          </Col>

          {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}> */}
          <Col md={4} className="course-card">
            <CourseCard
              imgPath={is}
              isBlog={false}
              title="Interview Skills"
              description="Our expertise lies in rendering expert advice and techniques to be used for taking up an interview round. We provide training for interviews is a process where individuals learn how to attend interviews confidently with the use of communication and other soft skills."
              ghLink="/enquiry"
            />
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="course-card">
            <CourseCard
              imgPath={cc}
              isBlog={false}
              title="Coaching Classes"
              description="Coaching classes:- (Pre-Primary to 10th STD) Are you looking to excel in your academic or professional journey? Our coaching classes are designed to help you achieve your goals, whether it's acing exams, mastering new skills, or preparing for competitive tests. With personalized attention, experienced instructors, and a structured curriculum, we provide the tools and support you need to succeed."
              ghLink="/enquiry"
            />
          </Col>

          {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}> */}
          <Col md={4} className="course-card">
            <CourseCard
              imgPath={phonics}
              isBlog={false}
              title="Phonics"
              description="Learning Phonics is a fun and child-centered approach to teaching literacy through synthetic phonics. With actions for each of the 42 letter sounds, the multi-sensory method is very motivating for children and teachers, who can see their students achieve."
              ghLink="/enquiry"
            />
          </Col>

          {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}> */}
          <Col md={4} className="course-card">
            <CourseCard
              imgPath={abacus}
              isBlog={false}
              title="Abacus"
              description="Our Abacus classes provide a fun and engaging way for students to enhance their mathematical abilities. Through hands-on learning, students will master the use of the abacus—a traditional counting tool used for centuries across various cultures. The curriculum covers fundamental arithmetic operations such as addition, subtraction, multiplication, and division. As students progress, they’ll also develop mental math skills, allowing them to perform complex calculations quickly and accurately without relying on calculators."
              ghLink="/enquiry"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Courses;
