import React from 'react';
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import ImageCarousel from "./ImageCarousel";
import Courses from "../Courses/Courses";
import About from "../About/About";
import Whyus from "../Whyus/Whyus";
import Gallery from "../Gallery/Gallery";
import Testimonials from "../Testimonials";
import Contact from "../Contact";

const Home = () => {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content"></Container>
      </Container>

      <ImageCarousel /> 
      <Courses />
      <About />
      <Whyus />
      <Gallery />
      <Testimonials />
      <Contact />
    </section>
  );
};
export default Home;