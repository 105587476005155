import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaArrowRight,
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import logo from "../Assets/logo.png";
import { AiOutlineMail, AiOutlineHome } from "react-icons/ai";

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  const mail = () => {
    window.location.href = "mailto:lotusenglishacademy37@gmail.com";
  };
  return (
    <Container fluid className="footer sticky">
      <Row>
        <Col md="3" className="footer-copywright">
          <img src={logo} className="img-fluid logo" alt="brand" />
          <h2 href="/about">
            <b className="white">Lotus English Academy</b>
          </h2>{" "}
          <br></br>
          <h6 className="white">
            Our spoken English classes are designed to improve the skill of
            speaking English with the support of proficient experienced
            faculties.
          </h6>
        </Col>

        <Col md="3" className="footer-copywright">
          <h2 className="white">
            <AiOutlineHome />
          </h2>
          <h2 className="white">
            <b>Office Address</b>
          </h2>

          <br></br>
          <h3>
            Office No 30 A, Royale Shell, Near D Y Patil Engineering
            College,Near Krishna Punjabi Hotel,Akurdi, Ravet, Pimpri Chinchwad
            New Town, Pune, 411044, Maharashtra
          </h3>
          <h3>lotusenglishacademy37@gmail.com</h3>
          <a href="mailto:lotusenglishacademy37@gmail.com" className="white">
            Email US
          </a>
          <h3>+91 9730799621</h3>
        </Col>

        <Col md="3" className="footer-copywright">
          <h2 className="white">
            <b>Links</b>
          </h2>
          <br></br>
          <br></br>
            <h3>
              <a href="/home" className="white">Home</a>
            </h3>
            <h3>
              <a href="/Courses" className="white">Services</a>
            </h3>
            <h3>
              <a href="/about" className="white">About Us</a>
            </h3>
            <h3>
              <a href="/contact" className="white">Contact</a>
            </h3>
        </Col>

        <Col md="3" className="footer-body">
          <div>
            <h2 style={{ textAlign: "center" }} >
              <b className="white">CONNECT WITH US</b>
            </h2>

            <br></br>
            <div>
              <h3><AiOutlineMail /></h3>
              <input type="email" placeholder="Enter your email id" />
              <button onClick={mail}>
                <FaArrowRight />
              </button>
              <hr />
            </div>
            <div className="social-icons">
              <a
                href="https://www.instagram.com/lotus_english_academy"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://wa.me/919730799621?text=Hello+I+need+more+information+about+classes"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100095294224268&mibextid=ZbWKwL"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="4" className="footer-copywright"></Col>
        <Col md="4" className="footer-copywright">
          <h3>Lotus English Academy © {year} - All Rights Reserved</h3>
        </Col>
        <Col md="4" className="footer-copywright"></Col>
      </Row>
    </Container>
  );
};

export default Footer;
