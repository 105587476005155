import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Img1 from './Caro1.png';
import Img2 from './Caro2.png'; 
import Img3 from './Caro3.png';
import Img4 from './Caro4.png';
import Img5 from './Caro5.png'; // Import your other images
import Img6 from './Caro6.png';

const ImageCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100" 
          src={Img1}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Img2}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100" 
          src={Img3}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100" 
          src={Img4}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Img5}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100" 
          src={Img6}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default ImageCarousel;
