import React from "react";
import { Carousel } from "react-bootstrap";
import Particle from "./Particle";
import {Container} from "react-bootstrap";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const Testimonials = () => {
  return (
    <Container fluid className="padd paddd">
      <Particle />
      <Container>
        <div style={styles.container}>
          <h2 style={styles.heading}>Testimonials</h2>
          <Carousel indicators={true} controls={false} interval={5000}>
            <Carousel.Item>
              <blockquote style={styles.quote}>
                <p style={styles.text}>
                  <FaQuoteLeft />
                  "I was having trouble with my English communication and Lotus
                  English Academy was able to make me fluent and confident. They
                  helped me better understand the core concepts for the course,
                  and they were very flexible and would work with my schedule."
                  <FaQuoteRight />
                </p>
                <footer style={styles.footer}>Swapnil</footer>
              </blockquote>
            </Carousel.Item>
            <Carousel.Item>
              <blockquote style={styles.quote}>
                <p style={styles.text}>
                  <FaQuoteLeft />
                  "It's a pleasure to learn English Communication so fast with a truly warm, personal and caring teacher of Lotus English Academy."
                  <FaQuoteRight />
                </p>
                <footer style={styles.footer}>Pratik Shinde</footer>
              </blockquote>
            </Carousel.Item>
            <Carousel.Item>
              <blockquote style={styles.quote}>
                <p style={styles.text}>
                  <FaQuoteLeft />
                  “The teaching of Lotus English Academy is so wonderful and refreshing!! they are so patient and supportive, but really knows how to motivate students. Also they are very great at building confidence and keeping lessons fun and engaging through a variety of activities that improve conversation, writing, and reading skills.”
                  <FaQuoteRight />
                </p>
                <footer style={styles.footer}>Sonu Kumawat</footer>
              </blockquote>
            </Carousel.Item>
            <Carousel.Item>
              <blockquote style={styles.quote}>
                <p style={styles.text}>
                  <FaQuoteLeft />
                  “The teaching of Lotus English Academy is so wonderful and refreshing!! they are so patient and supportive, but really knows how to motivate students. Also they are very great at building confidence and keeping lessons fun and engaging through a variety of activities that improve conversation, writing, and reading skills.”
                  <FaQuoteRight />
                </p>
                <footer style={styles.footer}>Pragati Bhondave</footer>
              </blockquote>
            </Carousel.Item>
            <Carousel.Item>
              <blockquote style={styles.quote}>
                <p style={styles.text}>
                <FaQuoteLeft />"The personalized attention I received from Lotus English
                  Academy was outstanding. They catered the lessons to my
                  specific needs, which helped me improve significantly in a
                  short period of time."<FaQuoteRight />
                </p>
                <footer style={styles.footer}>Ritika</footer>
              </blockquote>
            </Carousel.Item>
            <Carousel.Item>
              <blockquote style={styles.quote}>
                <p style={styles.text}>
                <FaQuoteLeft />"Lotus English Academy has been instrumental in my language
                  development. The instructors are knowledgeable and the
                  environment is very supportive. Highly recommended!"<FaQuoteRight />
                </p>
                <footer style={styles.footer}>Amit</footer>
              </blockquote>
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>
    </Container>
  );
}

const styles = {
  container: {
    textAlign: "center",
    padding: "50px 20px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    margin: "auto",
  },
  heading: {
    marginBottom: "30px",
    fontSize: "36px",
    color: "#333",
  },
  quote: {
    fontSize: "20px",
    fontStyle: "italic",
    color: "#555",
    margin: "0 auto",
    maxWidth: "700px",
    lineHeight: "1.6",
  },
  text: {
    marginBottom: "20px",
  },
  footer: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#666",
  },
};

export default Testimonials;