import React, { useState } from "react";
import "./Enquiry.css";
import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  Button,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";

export default function Enquiry() {
  const [subjects, setSubjects] = useState([]); // Handle multiple subjects
  const [message, setMessage] = useState(""); // Message state

  // Handle change for multiple selects
  const handleSubjectChange = (event) => {
    const value = event.target.value;
    setSubjects(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box className="box padd">
      <div className="container">
        <h3>COURSE ENQUIRY FORM</h3>
        <Stack spacing={4}>
          <FormControl className="form-control" id="subjects">
            <FormLabel>Course Name</FormLabel>
            <Select
              multiple
              value={subjects}
              onChange={handleSubjectChange}
              renderValue={(selected) => selected.join(", ")}
            >
              {[
                "Spoken English",
                "Public Speaking And Personality Development",
                "Interview Skills",
                "Coaching Classes",
                "Phonics",
                "Abacus",
              ].map((course) => (
                <MenuItem key={course} value={course}>
                  <Checkbox checked={subjects.indexOf(course) > -1} />
                  <ListItemText primary={course} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="form-control" id="message">
            <FormLabel>Message</FormLabel>
            <textarea
              placeholder="Enter your message here..."
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormControl>

          <Button
            onClick={() => {
              const whatsappMessage = `Hello, I need more information about the following courses: '${subjects.join(
                ", "
              )}. Message: ${message}`; // Use 'message' state here
              const encodedMessage = encodeURIComponent(whatsappMessage);
              const url = `https://wa.me/919730799621?text=${encodedMessage}`;
              window.open(url, "_blank");
            }}
            startIcon={<FaWhatsapp />}
          >
            Send via WhatsApp
          </Button>
        </Stack>
      </div>
    </Box>
  );
}
