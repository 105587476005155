import React from "react";
import "./Contact.css";
import Particle from "react-tsparticles";
import { Container } from "react-bootstrap";

const Contact = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <section className="contact-wrapper">
          <h2 className="common-heading">Feel Free to Contact us</h2>
          <br /> <br />
          
          <iframe
            title="Address"
            src="https://maps.google.com/maps?q=Lotus+English+Academy,Ravet+Pune&output=embed"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>
      </Container>
    </Container>
  );
};

export default Contact;
