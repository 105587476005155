import Softskills from "./SoftSkills.png";
import team from "./team.png";
import afford from "./afford.png";
import Particle from "../Particle";
import certi from "./Certifications.png";
import { Container, Row, Col } from "react-bootstrap";

const Whyus = () => {
  const styles = {
    card: {
      backgroundColor: "#f8f9fa",
      padding: "20px",
      borderRadius: "10px",
      textAlign: "center",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      width: "100%",
      height: "250px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    content: {
      padding: "30px",
    },
    title: {
      fontSize: "24px",
      marginBottom: "10px",
    },
    image: {
      width: "40%",
      height: "100%",
      objectFit: "cover",
    },
  };

  return (
    <Container fluid className="paddd padd">
      <Particle />
      <Container>
        <section className="center">
          <h2 className="black">
            <b>WHY US</b>
          </h2>
          <br />
          <Row className="justify-content-center">
            <Col md="4" className="d-flex justify-content-center">
              <div className="project-card-view" style={styles.card}>
                <div style={styles.content}>
                  <div>
                    <h3 className="black" style={styles.title}>
                      Soft Skills Training
                    </h3>
                    <div style={styles.controls}></div>
                  </div>
                  <img src={team} alt="Album cover" style={styles.image} />
                </div>
              </div>
            </Col>
            <Col md="4" className="d-flex justify-content-center">
              <div className="project-card-view" style={styles.card}>
                <div style={styles.content}>
                  <div>
                    <h3 className="black" style={styles.title}>
                      Individual Attention
                    </h3>
                    <div style={styles.controls}></div>
                  </div>
                  <img
                    src={Softskills}
                    alt="Album cover"
                    style={styles.image}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/* <span></span> */}
          <Row className="justify-content-center">
            <Col md="4" className="d-flex justify-content-center">
              <div className="project-card-view" style={styles.card}>
                <div style={styles.content}>
                  <div>
                    <h3 className="black" style={styles.title}>
                      Affordable
                    </h3>
                    <div style={styles.controls}></div>
                  </div>
                  <img src={afford} alt="Album cover" style={styles.image} />
                </div>
              </div>
            </Col>
            <Col md="4" className="d-flex justify-content-center">
              <div className="project-card-view" style={styles.card}>
                <div style={styles.content}>
                  <div>
                    <h3 className="black" style={styles.title}>
                      Certifications
                    </h3>
                    <div style={styles.controls}></div>
                  </div>
                  <img src={certi} alt="Album cover" style={styles.image} />
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </Container>
  );
};
export default Whyus;
