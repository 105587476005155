import React from "react";
import { Card } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import Students from "./Students.jpg";

const About = () => {
  return (
    <Container fluid className="about-section about-card about-card-view padd">
      <Card className="my-4 padd">
        <Card.Img
          alt="Card image cap"
          src={Students}
          style={{
            justifyContent: "center",
            alignContent: "center",
            width: "100%", 
            height: "200px",
            objectFit: "cover",
          }}
          top
          width="30%"
        />
        <Card.Body>
          <Card.Title tag="h5">
            <h3 className="black">
              <b>ABOUT US</b>
            </h3>
          </Card.Title>
          <Card.Text>
            <h6 className="black">
              We, Lotus English Academy, situated at Pimpri Chinchwad , Pune,
              Maharashtra are one of the leading institute for Spoken English
              and Soft Skills Trainings. Our Spoken English course is designed
              to help you achieve a natural speaking style, as well as gain
              confidence in speaking English. With our Spoken English modules,
              you will talk, practice and improve your pronunciation and
              intonation. Our Soft skills training course is focuses on the
              development of abilities such as communication, teamwork and
              problem solving. These are non-technical competencies that can
              help individuals to achieve career success and interact with
              others effectively. Also this will improve an individual's
              performance and confidence.
            </h6>
          </Card.Text>
        </Card.Body>
      </Card>
      <Particle />
    </Container>
  );
};

export default About;
