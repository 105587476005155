import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Gallery from "./components/Gallery/Gallery";
import About from "./components/About/About";
import Whyus from "./components/Whyus/Whyus";
import Footer from "./components/Footer";
import Enquiry from "./components/Enquiry";
import Testimonials from "./components/Testimonials";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Courses from "./components/Courses/Courses";
import Contact from "./components/Contact";
import whatsapp from "./Assets/whatsapp.png"

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Router>
        <Preloader load={load} />
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/Courses" element={<Courses />} />
            <Route path="/enquiry" element={<Enquiry />} />
            <Route path="/about" element={<About />} />
            <Route path="/Whyus" element={<Whyus />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </div>
      </Router>
      <div
      className="whatsapp-button-container"
      style={{
        zIndex: 6,
        right: '1rem',
        padding: '1rem',
        left: 'initial',
      }}
    >
      <a
        href="https://wa.me/919730799621?text=Hello+I+need+more+information+about+classes"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsapp} width="60" alt="WhatsApp" className="whatsapp-icon" />
      </a>
    </div>
    </>
  );
}

export default App;
