import React from "react";
import { Carousel } from "react-bootstrap";
import Particle from "../Particle";
import { Container } from "react-bootstrap";

import Pic1 from "../Gallery/1.jpg";
import Pic2 from "../Gallery/2.jpg";
import Pic3 from "../Gallery/3.jpg";
import Pic4 from "../Gallery/4.jpg";
import Pic5 from "../Gallery/5.jpg";
import Pic6 from "../Gallery/6.jpg";
import Pic7 from "../Gallery/7.jpg";
import Pic8 from "../Gallery/8.jpg";
import Pic9 from "../Gallery/9.jpg";
import Pic10 from "../Gallery/10.jpg";
import Pic11 from "../Gallery/11.jpg";
import Pic12 from "../Gallery/12.jpg";
import Pic13 from "../Gallery/13.jpg";
import Pic14 from "../Gallery/14.jpg";
import Pic15 from "../Gallery/15.jpg";
import Pic16 from "../Gallery/16.jpg";
import Pic17 from "../Gallery/17.jpg";
import Pic18 from "../Gallery/18.jpg";
import Pic19 from "../Gallery/19.jpg";
import Pic20 from "../Gallery/20.jpg";
import Pic21 from "../Gallery/21.jpg";
import Pic22 from "../Gallery/22.jpg";
import Pic23 from "../Gallery/23.jpg";
import Pic24 from "../Gallery/24.jpg";
import Pic25 from "../Gallery/25.jpg";
import Pic26 from "../Gallery/26.jpg";
import Pic27 from "../Gallery/27.jpg";
import Pic28 from "../Gallery/28.jpg";
import Pic29 from "../Gallery/29.jpg";
import Pic30 from "../Gallery/30.jpg";
import Pic31 from "../Gallery/31.jpg";
import Pic32 from "../Gallery/32.jpg";
import Pic33 from "../Gallery/33.jpg";

const Gallery= () => {
  // Array of imported images
  const caro = [
    Pic1, Pic2, Pic3, Pic4, Pic5, Pic6, Pic7, Pic8, Pic9, Pic10,
    Pic11, Pic12, Pic13, Pic14, Pic15, Pic16, Pic17, Pic18,
    Pic19, Pic20, Pic21, Pic22, Pic23, Pic24, Pic25, Pic26,
    Pic27, Pic28, Pic29, Pic30, Pic31, Pic32, Pic33
  ];

  // Split the images into groups of 9 for each carousel slide
  const chunkedImages = [];
  for (let i = 0; i < caro.length; i += 9) {
    chunkedImages.push(caro.slice(i, i + 9));
  }

  return (
    <Container fluid className="paddd padd">
      <Particle />
      <Container>
        <div style={styles.container}>
          <h2 style={styles.heading}>Gallery</h2>
          <Carousel interval={5000}>
            {chunkedImages.map((group, index) => (
              <Carousel.Item key={index}>
                <div style={styles.gridContainer}>
                  {group.map((src, idx) => (
                    <div key={idx} style={styles.imageContainer}>
                      <img
                        src={src}
                        alt={`Gallery ${idx + 1}`}
                        style={styles.image}
                      />
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Container>
    </Container>
  );
}

const styles = {
  container: {
    padding: "20px",
    textAlign: "center",
    maxWidth: "1200px",
    margin: "auto",
  },
  heading: {
    fontSize: "36px",
    marginBottom: "20px",
    color: "#333",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
  },
  imageContainer: {
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  image: {
    width: "100%", 
    height: "200px", 
    objectFit: "cover",
  },
};

export default Gallery;